import React, { useState } from 'react'


const SearchLocations = ({setLocations, locations}) => {
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [location, setLocation] = useState('')
  const [result, setResult] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const api_key = "AIzaSyBQ0WyEkHrApFuWBfxCToCSJbA7l81FD9U"
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${api_key}`

  const handlePossibleLocaitons = async() => {
    setLoading(true)
    setError(null)

    fetch(url)
      .then(res => res.json())
      .then(data => {setResult(data.results); console.log(data.results)})
      .then(() => setLoading(false))
      .catch(error => setError(error))
  }

  const handleGeolocation = (res) => {
    console.log(`${res.geometry.location.lat}, ${res.geometry.location.lng}`)
    setLocations(`${res.geometry.location.lat}, ${res.geometry.location.lng}`)
  }

  return (
    <div>
      <input
        value={location}
        onChange={e => setLocation(e.target.value)} 
        type='text' 
        placeholder='Enter location name' 
        className="p-[15px] border-[1px] rounded-lg border-black text-[16px]/[24px] font-[PingFangHK] w-full"
      />
      <button
        type='button' 
        onClick={handlePossibleLocaitons}
        className='bg-white p-1 rounded-lg mt-1 w-full'
      >
        {loading && !error ? <p>Loading...</p> : <p>Search</p>}
      </button>

      <div className='bg-white mt-1'>
        <h2>Locations available: </h2>
        {result.length ? result.map(res => (
          <div className='border border-black m-3'>
            <li>{res.address_components[0].long_name}, {res.address_components[1].long_name}</li>
            <button 
              onClick={() => handleGeolocation(res)}
              className='underline decoration-blue-500 disabled:bg-gray-200'
              disabled={locations === `${res.geometry.location.lat}, ${res.geometry.location.lng}`}
            >
              Select this location
            </button>
            {locations === `${res.geometry.location.lat}, ${res.geometry.location.lng}` && (
                <div className='flex justify-around'>
                  <p className='bg-green-500 flex justify-center gap-10'>Selected</p>
                  <p onClick={() => setLocations('')} className='cursor-pointer text-red-500'>Remove</p>
                </div>
              )}
          </div>
        )) : <p>No locations available for that input</p>}
      </div>
          OR
            <br/>
          Lat
            <br/>
            <input onChange={e=>{
              setLat(parseFloat(e.target.value))
            }}/>
            <br/>
            Lng
            <br/>
            <input className='mt-3' onChange={e=>{
              setLng(parseFloat(e.target.value))
            }} />
            <br/>
            <button 
        type='button'  className='bg-amber-300' onClick={()=>{
              setLocations(''+lat+','+lng)
            }}>Set input locations</button>
    </div>
  )
}

export default SearchLocations