export const data = [
  {
    category: [
      "Meetup",
      "Education",
      "Party"
    ],
    description: "grupa",
    events: [
      {
        category: [
          "Education"
        ],
        days: [
          {
            date: "19/10/2023",
            description: "",
            endTime: null,
            id: "5680bb5b-5a2c-436a-84f8-e69c064bce3a",
            idealEndTime: null,
            idealStartTime: null,
            images: [
              "https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg"
            ],
            location: [
              null
            ],
            startTime: null
          }
        ],
        defaultRating: 5,
        id: "3161cb06-3a58-42ff-8dab-3e9c43b05442",
        repeatAfter: 3,
        timestamp: "2023-10-19T10:08:46.233Z",
        title: "event za grupu"
      }
    ],
    images: [
      "https://firebasestorage.googleapis.com/v0/b/maptodo-480f3.appspot.com/o/events%2Flukomir-2.jpg?alt=media&token=40965629-2bd0-4c22-8a20-515d08c57de6"
    ],
    timestamp: "2023-10-19T09:10:48.318Z",
    title: "grupa"
  },
  {
    category: [
      "Meetup",
      "Education",
      "Party"
    ],
    description: "grupa",
    events: [
      {
        category: [
          "Education"
        ],
        days: [
          {
            date: "19/10/2023",
            description: "",
            endTime: null,
            id: "5680bb5b-5a2c-436a-84f8-e69c064bce3a",
            idealEndTime: null,
            idealStartTime: null,
            images: [
              "https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg"
            ],
            location: [
              null
            ],
            startTime: null
          }
        ],
        defaultRating: 5,
        id: "3161cb06-3a58-42ff-8dab-3e9c43b05442",
        repeatAfter: 3,
        timestamp: "2023-10-19T10:08:46.233Z",
        title: "event za grupu"
      }
    ],
    images: [
      "https://firebasestorage.googleapis.com/v0/b/maptodo-480f3.appspot.com/o/events%2Flukomir-2.jpg?alt=media&token=40965629-2bd0-4c22-8a20-515d08c57de6"
    ],
    timestamp: "2023-10-19T09:10:48.318Z",
    title: "grupa"
  },
  {
    category: [
      "Meetup",
      "Education",
      "Party"
    ],
    description: "grupa",
    events: [
      {
        category: [
          "Education"
        ],
        days: [
          {
            date: "19/10/2023",
            description: "",
            endTime: null,
            id: "5680bb5b-5a2c-436a-84f8-e69c064bce3a",
            idealEndTime: null,
            idealStartTime: null,
            images: [
              "https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg"
            ],
            location: [
              null
            ],
            startTime: null
          }
        ],
        defaultRating: 5,
        id: "3161cb06-3a58-42ff-8dab-3e9c43b05442",
        repeatAfter: 3,
        timestamp: "2023-10-19T10:08:46.233Z",
        title: "event za grupu"
      }
    ],
    images: [
      "https://firebasestorage.googleapis.com/v0/b/maptodo-480f3.appspot.com/o/events%2Flukomir-2.jpg?alt=media&token=40965629-2bd0-4c22-8a20-515d08c57de6"
    ],
    timestamp: "2023-10-19T09:10:48.318Z",
    title: "grupa"
  },
  {
    category: [
      "Meetup",
      "Education",
      "Party"
    ],
    description: "grupa",
    events: [
      {
        category: [
          "Education"
        ],
        days: [
          {
            date: "19/10/2023",
            description: "",
            endTime: null,
            id: "5680bb5b-5a2c-436a-84f8-e69c064bce3a",
            idealEndTime: null,
            idealStartTime: null,
            images: [
              "https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg"
            ],
            location: [
              null
            ],
            startTime: null
          }
        ],
        defaultRating: 5,
        id: "3161cb06-3a58-42ff-8dab-3e9c43b05442",
        repeatAfter: 3,
        timestamp: "2023-10-19T10:08:46.233Z",
        title: "event za grupu"
      }
    ],
    images: [
      "https://firebasestorage.googleapis.com/v0/b/maptodo-480f3.appspot.com/o/events%2Flukomir-2.jpg?alt=media&token=40965629-2bd0-4c22-8a20-515d08c57de6"
    ],
    timestamp: "2023-10-19T09:10:48.318Z",
    title: "grupa"
  },
  {
    category: [
      "Meetup",
      "Education",
      "Party"
    ],
    description: "grupa",
    events: [
      {
        category: [
          "Education"
        ],
        days: [
          {
            date: "19/10/2023",
            description: "",
            endTime: null,
            id: "5680bb5b-5a2c-436a-84f8-e69c064bce3a",
            idealEndTime: null,
            idealStartTime: null,
            images: [
              "https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg"
            ],
            location: [
              null
            ],
            startTime: null
          }
        ],
        defaultRating: 5,
        id: "3161cb06-3a58-42ff-8dab-3e9c43b05442",
        repeatAfter: 3,
        timestamp: "2023-10-19T10:08:46.233Z",
        title: "event za grupu"
      }
    ],
    images: [
      "https://firebasestorage.googleapis.com/v0/b/maptodo-480f3.appspot.com/o/events%2Flukomir-2.jpg?alt=media&token=40965629-2bd0-4c22-8a20-515d08c57de6"
    ],
    timestamp: "2023-10-19T09:10:48.318Z",
    title: "grupa"
  }
]