import React from "react";
import background from "../../assets/images/backgroundImg.png";
export const ImgWrapper = ({ img, imgWidth, imgHeight, rotationAngle, scaleX, translateX, translateY}) => {
  return (
    <div
      className=" relative flex justify-center items-center "
      style={{ width: imgWidth, height: imgHeight }}
    >
      <img className="absolute -z-10 " style={{transform: `rotate(${rotationAngle}deg) scaleX(${scaleX}) translateX(${translateX}px) translateY(${translateY}px)`}} src={background} />
      <img className=" mt-[15px] ml-5  w-[89%] md:ml-8" src={img} alt="Picturee" />
    </div>
  );
};
