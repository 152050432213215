import logo from './logo.svg';
import './App.css';
import BetaUserPage from './components/beta-user-page/BetaUserPage';
import V2Events from './components/events/V2Events';
import Locations from './components/locations/Locations';
import { BrowserRouter ,Routes, Route } from "react-router-dom";
import AddRoutes from './components/routes/AddRoutes';
import GroupEvents from './components/events/GroupEvents';
import Events from './components/events/Events';
import Cms from './components/cms/Cms';
import LandingPage from './LandingPage';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import { MiddlePart } from './components/middle-part/MiddlePart';
function App() {
 
  return (
    <div className="App"> 
    <BrowserRouter basename="/">
    <Routes>
        <Route path="/" element={<LandingPage />}/>
        <Route path="/admin/v2events" element={<V2Events />}/>
        <Route path="/admin/events" element={<Events />}/>
        <Route path="/admin/groupEvents" element={<GroupEvents />}/>
        <Route path="/admin/locations" element={<Locations />}/>
        <Route path="/admin/routes" element={<AddRoutes />} />
        <Route path="/admin/cms" element={<Cms />} />
      </Routes>
    </BrowserRouter>

    </div>
  );
}

export default App;
