import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { useState } from 'react'
import Input from '../input/Input';
import ShadowBox from '../shadow-box/ShadowBox';

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

import { storage } from '../../firebase/config';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import SearchLocations from '../searchLocations/SearchLocations';

const GroupEvents = ({ }) =>
{

  const options = [
    { value: "Music", label: 'Music' },
    { value: "Meetup", label: 'Meetup' },
    { value: "Education", label: 'Education' },
    { value: "Party", label: 'Party' },
    { value: "Exhibition", label: 'Exhibition' },
    { value: "Fashion", label: 'Fashion' },
    { value: "Competition", label: 'Competition' },
    { value: "Sport", label: 'Sport' },
    { value: "Festival", label: 'Festival' },
    { value: "Business", label: 'Business' },
    { value: "Charity", label: 'Charity' },
    { value: "Race", label: 'Race' },
    { value: "Trip", label: 'Trip' },
    { value: "Fair", label: 'Fair' }
  ];

  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [categories, setCategories] = useState('');
  const [rating, setRating] = useState(1);
  const [images, setImages] = useState('');
  const [uploadedImages, setUploadedImages] = useState([])

  const [sending, setSending] = useState(false);
  const [env, setEnv] = useState('dev')

  const [imageUploadError, setImageUploadError] = useState(null)
  const [imageUploaded, setImageUpload] = useState(false)

  const [img, setImg] = useState([])

  const handleChange = async(e) => {
    setImg([...img, e.target.files[0]])
  }

  const handleUpload = async () => {
    console.log(img[0].name)
    img.forEach(file => {
      let imageRef = ref(storage, `events/${file.name}`)
      try {
      uploadBytes(imageRef, file).then(snapshot => {
        getDownloadURL(imageRef).then(url => {
          console.log(url)
          setUploadedImages(prev => [...prev, url])
          if(url) setImageUpload(true)
          if(!url) setImageUploadError('image not uploaded')
        })
      })
      } catch(e) {
        console.log(e)
        setImageUploadError(e)
      }
    })
  }
  

  return (
    <div className="h-[100vh] overflow-y-scroll" style={{ backgroundImage: 'linear-gradient(136deg, #FFF 7.81%, #C9FFD5 73.27%, #D2F0FD 100%)' }}>
      <div className="pointer-events-none absolute top-0 left-0 bottom-0 right-0 " style={{ backgroundColor: sending ? '#3d3d3d75' : '#ffffff00', }}>{sending ? <div className="bg-white">Recording your data...</div> : <div></div>}</div>
      <form onSubmit={(e) =>
        {
          e.preventDefault()
          if (!sending)
          {
            setSending(true)
            //fetch((env==='prod'?'https://app-h4jqfg6cqq-uc.a.run.app':'https://app-4romxvc23a-uc.a.run.app')+'/v1/events/addGeneralEvent',
            fetch("https://app-4romxvc23a-uc.a.run.app/v2/events/addEvent",
              {
                method: 'POST',
                body: JSON.stringify({
                  category: categories.map(item => Number.isInteger(item.value) ? item.value : item.value[0].toUpperCase()+item.value.slice(1)),
                  title: title,
                  description: desc,
                  images: images.length > 5 ? [...uploadedImages, ...images.split(',')] : [...uploadedImages],
                  timestamp: new Date(),
                  events: []
                }),
                headers: {
                  'Content-Type': 'application/json'
                },
                redirect: 'follow'
              })
              .then(response => response.text())
              .then(result =>
              {
                setSending(false)
                alert('Recorded data: ', result)
              })
              .catch(error =>
              {
                alert('Error recording data')
                setSending(false)
              });
          }
        }} className='flex flex-col w-60 mx-auto'>
        <a 
          href='https://maptobe.com/admin/v2events' 
          target='_blank' 
          className='underline decoration-sky-500 text-sm'
        >
          Go to add v2 event
        </a>
      <h1 className='text-[16px]'>Select enviroment to post event:</h1>
      <label className='mb-2'>
        Production
        <input 
          checked={env === 'prod'}
          className='ml-5' 
          type='radio' 
          onChange={e => setEnv(e.target.value)} 
          value='prod' 
        />
      </label>
      <label className='mb-2'>
        Development
        <input 
          checked={env === 'dev'}
          className='ml-5' 
          type='radio' 
          onChange={e => setEnv(e.target.value)} 
          value='dev' 
        />
      </label>
      Title*:
      <br />
      <Input setText={setTitle}></Input>
      <br />
      Description*:
      <br />
      {/* <Input setText={setDesc}></Input> */}
      <textarea
          cols={50}
          rows={4}
          placeholder="description..." 
          onChange={e => setDesc(e.target.value)}
          class="p-[15px] border-[1px] rounded-lg border-black text-[16px]/[24px] font-[PingFangHK]"
        />
         <br />
        default rating* (1-5)
        <br />
        <input type='number' required min={1} max={5} value={rating} onChange={(e => setRating(e.target.value))} />
        <br />
      Images: (example: https://linkToImage.png,https://anotherLinkToImage.jpg)
      <br />
      <input 
        className="p-[15px] border-[1px] rounded-lg border-black text-[16px]/[24px] font-[PingFangHK]"
        type='text'
        onChange={(e) => setImages(e.target.value)}
        value={images}
      />
      <input type='file' accept="image/*" onChange={handleChange} />
      <div>
        Images choosen:
        <br />
        {img.map((file, i) => (
          <li className='bg-white' key={file.name+i}>
            {file.name}
          </li>
        ))}
        <button 
          onClick={handleUpload}
          className='bg-green-800 text-white p-2 rounded mt-2'
          type='button'
        >
          Upload images
        </button>
        <br />
        {imageUploaded ? <p className='text-green-400 bg-black'>'Uploaded'</p> : 'still not uploaded'}
        {imageUploadError && <p>{imageUploadError}  </p>}
      </div>
    
      <br />
      Select categories
        <Select 
          defaultValue={categories}
          onChange={setCategories}
          options={options}
          isMulti={true}
          menuPlacement='auto'
          required
        />
      <br />
      <div className="m-[auto] flex flex-1 my-24 justify-center ">  
        <ShadowBox w={200}>
          <button type='submit'>Send</button>
        </ShadowBox>
      </div>
      </form>
    </div>
  );
};

export default GroupEvents;