import React, { useState } from 'react'
import { data } from '../../assets/data'

const Cms = () => {

  const [selected, setSelected] = useState('events')

  return (
    <div className='h-[100vh] w-full flex'>
      <div className='bg-sky-100 h-full w-52 p-5 space-y-10 basis-1/6'>
        <h1 className='font-bold'>Admin Dashboard</h1>

        <div className='flex flex-col gap-5'>
          <a 
            className={selected === 'events' ? 'bg-black text-white cursor-pointer p-1 rounded text-sm' : " cursor-pointer text-sm"}
            onClick={() => setSelected('events')}
          >
            Events
          </a>
          <a 
            className={selected === 'locations' ? 'bg-black text-white cursor-pointer p-1 rounded text-sm' : " cursor-pointer text-sm"}
            onClick={() => setSelected('locations')}
          >
            Locations
          </a>
        </div>
      </div>

      <div className='basis-5/6 p-12 flex flex-col items-start'>
        <h1 className='text-lg font-bold'>Events:</h1>

        <div>
          {data.map(group => (
            <h1>Grupa: {group.title}</h1>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Cms