import React, { useState } from 'react';
import './HeaderStyle.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
      <div className='header'>
        <div className='overlay'></div>
        <div className='navigation'>
          <div className='logo'>
            <p>MAPTOBE</p>
          </div>
          <div className='navbar'>
            {/* <div className={`hamburger-menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <div className='bar'></div>
              <div className='bar'></div>
              <div className='bar'></div>
            </div> */}
            <ul className={`navList ${menuOpen ? 'open' : ''}`}>
              {/* <li>Product</li>
              <li>Services</li>
              <li>Contact</li>
              <li>Log in</li>
              <li>
                <button className='navButton'>Try it free</button>  
              </li>  */}
            </ul>
          </div>
        </div>
        <div className='headerMainSection'>
          <div className='headerLeftSection'>
            <img src={require('../../assets/images/logo-mixed.png')} alt='Logo' />
            <div className='ellipse'></div>
            <p className='leftSectionTitle'>MAPTOBE</p>
            <div className='leftQuote'>
              <img src={require('../../assets/images/quote.png')} alt='quote' /> 
            </div>
            <p className='leftSectionText'>
                Early alpha is out ! 
            </p>
            <div className='rightQuote'>
              <img src={require('../../assets/images/quote.png')} alt='quote' /> 
            </div>
          </div>
          <div className='illustration'>
            <img 
              src={require('../../assets/images/paper-plane.png')} 
              alt='Paper plane' 
              className='paperPlane'
            />
            <img 
              src={require('../../assets/images/curved-line.png')} 
              alt='first curverd line'
              className='firstLine' 
            />
            <img 
              src={require('../../assets/images/curved-line.png')} 
              alt='second curverd line'
              className='secondLine' 
            />
            <img 
              src={require('../../assets/images/curved-line.png')} 
              alt='third curverd line'
              className='thirdLine' 
            />
            <img 
              src={require('../../assets/images/curved-line.png')} 
              alt='fourth curverd line'
              className='fourthLine' 
            />
          </div>
          <div className='headerRightSection'>
            <h2 className='rightSectionTitle'>Connecting you <br /> to the world</h2>
            <p className='rightSectionText'>
              MapToBe is an AR Social Network Game that is going to inspire you to explore the world and help you connect with people you will enjoy life with 
            </p>
            <div className="downloadButton" >
              <div className='shadowBox'></div>
              <a  target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.maptodo">
                <button className='downloadNow'>Download Now</button>
              </a>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Header;