import React, { useState, useEffect} from "react";
import { ImgWrapper } from "../image-wrapper/imgWrapper";

export const MiddlePart = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;

      // Adjust the threshold as needed
      if (scrollY > 0.2 * window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add scroll event listener when component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove scroll event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="w-[100%] flex flex-col items-center lg:mt-48 lg:mb-64 sm:mt-16 sm:mb-20">
      <div className="flex-col w-[100%] justify-center  flex items-center md:flex-row md:w-[90%]">
        <div className=" w-[90%]  justify-center pb-[50px] md:w-[443px] md:text-start ">
          <h2 className="pt-10 pb-10 font-bold text-[48px] leading-[60px] text-custom-title font-[Raleway] ">
            Your best companion while exploring Sarajevo
          </h2>
          <p className="text-[20px] font-normal text-custom-pharagraf font-[OpenSans]">
          We have launched an early alpha primarily focused on Sarajevo, Bosnia, and you can download the app right away and explore the city of Sarajevo. There are two key app sections one is for all of the events happening in Sarajevo, and another is of beautiful places. 
          </p>
        </div>
        <div className="flex justify-center md:ml-14">
          <img
            className="w-[80%]"
            src={require("../../assets/images/Phone.png")}
            alt="Phone"
          />
        </div>
      </div>
      <div className="mt-20 w-[100%] flex justify-end">
        <img
          src={require("../../assets/images/Ilustration.png")}
          alt="ilustration1"
          className="w-[70%] md:w-[43%]"
        />
      </div>
      {/*picture of Nature - Discover Sarajevo’s nature gems*/}
      <div className="mt-[100px] md:mt-[200px] w-[100%] justify-center flex items-center flex-col md:flex-row md:w-[90%]">
        <div className="flex  w-[90%] md:w-[50%]">
          <ImgWrapper
            img={require("../../assets/images/Rectangle.png")}
            imgWidth={""}
            imgHeight={""}
          />
        </div>
        <div className="w-[90%] mt-20  justify-center pb-[50px] md:w-[443px] md:text-start md:ml-28">
          <h2 className="pb-10 font-bold text-[48px] leading-[60px] text-custom-title font-[Raleway] ">
            Discover Bosnia's hidden gems
          </h2>
          <p className="text-[20px] leading-7 font-normal text-custom-pharagraf mb-6">
            Inside of MapToBe you can find a currated list of hidden gems of not just Sarajevo but entire Bosnia & Herzegowina. All of the nature, history and art. Make sure to remember to use the app whenever you want to plan a trip in Bosnia!  
          </p>
          {/* <a href="#" className="text-custom-link underline">
            Learn more <img src={require('../../assets/images/arrow_forward_24px.png')} className="inline-block" />
          </a> */}
        </div>
      </div>
      {/* image of church - Discover Sarajevo’s nature gems*/}
      <div className="md:mt-[100px] w-[100%] justify-center flex items-center flex-col md:w-[90%] md:flex-row-reverse">
        <div className="flex  w-[90%] md:w-[50%] transform -scale--100">
          <ImgWrapper
            img={require("../../assets/images/event.jpg")}
            imgWidth={""}
            imgHeight={""}
            rotationAngle={180}
            translateX={0}
            translateY={-15}
            scaleX={-1}
          />
        </div>
        <div className="w-[90%] mt-20  justify-center pb-[50px] md:w-[443px] md:text-start md:mr-28">
          <h2 className="pb-10 font-bold text-[48px] leading-[60px] text-custom-title font-[Raleway] ">
            Best currated list of Events in Sarajevo
          </h2>
          <p className="text-[20px] leading-7 font-normal text-custom-pharagraf mb-6">
            MapToBe also contains the best list of amazing events in Sarajevo. Make sure to never miss out on all the fun and find the best parties, exhibitions and events happening for yourself and your friends. 
          </p>
          {/* <a href="#" className="text-custom-link underline">
            Learn more <img src={require('../../assets/images/arrow_forward_24px.png')} className="inline-block" />
          </a> */}
        </div>
      </div>
      <div className=" w-[100%] mt-14 flex justify-start md:mt-[-40px] ">
        <img
          src={require("../../assets/images/Ilustration.png")}
          alt="ilustration2"
          className=" w-[70%] transform -scale-x-100 md:w-[35%] "
        />
      </div>
      <button
        onClick={scrollToTop}
        style={{display: isVisible ? 'inline-block' : 'none'}}
        className="visible fixed bottom-[50px] right-[10px] lg:right-[50px]"
      >
        <img src={require("../../assets/images/iconDownArrowBBold.png")} />
      </button>
    </div>
  );
};
